import commonHelper from '@/app/utility/common.helper.utility';
import addEditInvestorLease from './addEditInvestorLease';
import tenantLease from '../../leases/tenantLease';
import party from '@/app/components/generalLedger/party';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import fmsSubUnit from '../../../../fms/unitInventory/fmsSubunit';
export default {
  name: 'masterAgreement',
  watch: {
    currentPage: function() {
      this.getInvestorList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvestorList();
    }
  },
  props: ['showInvestorLeaseModal'],
  components: {
    commonHelper,
    addEditInvestorLease,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    tenantLease,
    party,
    fmsSubUnit
  },
  data() {
    return {
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      showCommonModal: false,
      showPartyModal: false,
      parent_value_set_id: null,
      tenantNumber: '',
      investorNumber: '',
      unitName: '',
      partyName: '',
      appStatus: {
        value: null,
        text: null
      },
      tenantLease: {
        value: null,
        text: null
      },
      selectedParty: {
        name: null,
        id: null
      },
      fmsSubUnit: {
        value: null,
        text: null
      },
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leaseUnitRowDetails: null,
      gridData: [],
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_investor_agreement_date',
          label: 'Agreement Date'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No.'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'lease_prj_name'
        },
        {
          key: 'lease_fms_sub_unit_name',
          label: 'FMS Sub Unit'
        },
        // {
        //   key: 'sd',
        //   label: 'Security Deposit'
        // },
        {
          key: 'lease_party_id',
          label: 'Party Id'
        },
        {
          key: 'lease_party_name',
          label: 'Party Name'
        },
        {
          key: 'lease_prj_unit_name',
          label: 'Lease Office'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'lease_description'
        },
        {
          key: 'lease_status_meaning',
          label: 'Lease Status'
        }
      ],
      selectedLeaseDetails: null,
      showAddLeaseModal: false,
      showHistoryBtn: false,
      leaseInvestorHdrfId: null,
      historyType: null,
      showHistory: false,
      showOpenKmModal: false,
      openkmAuthToken: null,
      selectedGridData: null,
      modalType: null,
      showFmsSubUnitModal: false,
      schStatus: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.showInvestorLeaseModal) {
      this.getInvestorList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedLeaseDetails = null;
          this.showAddLeaseModal = true;
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leases/getInvestorList',
            'investor',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getInvestorList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_investor_no: this.investorNumber,
        lease_tenant_agreement_hdr_id: this.tenantLease.value,
        lease_prj_id: this.leasePrj.value,
        lease_party_id: this.selectedParty.id,
        unit_name: this.unitName,
        approval_status: this.appStatus.value,
        lease_fms_sub_unit_id: this.fmsSubUnit.value,
        lease_status: this.schStatus.value
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getInvestorList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.investorNumber = null;
      this.unitName = '';
      this.partyName = '';
      this.appStatus = {
        value: null,
        text: null
      };
      this.leasePrj = {
        value: null,
        text: null
      };
      this.selectedParty = {
        name: null,
        id: null
      };
      this.tenantLease = {
        value: null,
        text: null
      };
      this.fmsSubUnit = {
        value: null,
        text: null
      };
      this.schStatus = {
        value: null,
        text: null
      };
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.showHistoryBtn = false;
    },
    rowSelected(item) {
      if (this.showInvestorLeaseModal) {
        this.$emit('selectedLeaseInvestor', item);
      } else {
        this.selectedLeaseDetails = Object.assign({}, item);
        this.showAddLeaseModal = true;
        this.leaseInvestorHdrfId = item.lease_investor_agreement_hdr_id;
      }
      this.getLeaseInvestorDetails(item.lease_investor_agreement_hdr_id);
    },
    getLeaseInvestorDetails(leaseInvestorHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDetails', leaseInvestorHdrId)
        .then(response => {
          if (response.status === 200) {
            this.selectedLeaseDetails = response.data.data;
            this.selectedLeaseDetails.period_from_to = [
              new Date(this.selectedLeaseDetails.from_date),
              new Date(this.selectedLeaseDetails.to_date)
            ];
            this.showAddLeaseModal = true;
          }
          this.loader = false;
        })
        .catch(() => (this.loader = false));
    },
    selectedLease(item) {
      this.selectedLeaseDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.leaseInvestorHdrfId = item.lease_investor_agreement_hdr_id;
    },
    hideAddLeaseModal() {
      this.showAddLeaseModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'APPROVAL_STATUS') {
        this.appStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.schStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
    },
    updateLeaseUnitList() {
      this.leaseUnitData = [];
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
    },
    selectedLeaseTenant(item) {
      this.tenantLease = {
        value: item.lease_tenant_agreement_hdr_id,
        text: item.lease_tenant_agreement_no
      };
      this.showHideCommonLeaseModal(false, null);
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.selectedParty.name = item.party_name;
      this.selectedParty.id = item.party_id;
      this.showPartyModal = false;
    },
    showHideFmsSubUnitModal(flag) {
      this.showFmsSubUnitModal = flag;
    },
    selectedFmsSubUnit(item) {
      this.fmsSubUnit.text = item.fms_sub_unit_name;
      this.fmsSubUnit.value = item.fms_sub_unit_id;
      this.showHideFmsSubUnitModal(false);
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'LEASE_PROJECT_LIST':
          this.leasePrj = {
            text: null,
            value: null
          };
          break;
        case 'APPROVAL_STATUS':
          this.appStatus = {
            text: null,
            value: null
          };
          break;
        case 'SCHEDULE_STATUS_VSET':
          this.schStatus = {
            text: null,
            value: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
